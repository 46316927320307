var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.report, editor: "veldwerk" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      _vm.isAllowedToView
        ? [
            _vm.report
              ? _c(
                  "v-container",
                  {
                    staticClass:
                      "reportContainer container--wide reportContainer--veldwerk",
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    !_vm.isLoading
                      ? _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs12: "", lg5: "" } }, [
                              _c("div", { staticClass: "scrollColumn" }, [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "reject-border":
                                        _vm.report.reject_reasons &&
                                        _vm.report.reject_reasons.length
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "elementPanel profile" },
                                      [
                                        _vm.isLoading
                                          ? _c("LoaderCard", {
                                              attrs: {
                                                flat: "",
                                                type: "spinner--center",
                                                minHeight: "300px"
                                              }
                                            })
                                          : _vm._e(),
                                        !_vm.isLoading
                                          ? [
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "profileContainer",
                                                  attrs: { row: "", wrap: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    [
                                                      [
                                                        _c(
                                                          "h1",
                                                          {
                                                            staticClass:
                                                              "profile__userName"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.report
                                                                  .case_number
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "profile__userRoles"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.report
                                                                    .type
                                                                    ? _vm.report
                                                                        .type
                                                                        .name
                                                                    : ""
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Naam aanvrager"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.report.applicant
                                                              ? _vm.report
                                                                  .applicant
                                                                  .name
                                                              : ""
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [_vm._v("Adres")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _vm.report.address
                                                        ? _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: _vm.report.googleMapUrl(),
                                                                target: "_blank"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .address
                                                                      .street
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .address
                                                                      .number
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .address
                                                                      .number_add
                                                                  ) +
                                                                  ", " +
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .address
                                                                      .postcode
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .address
                                                                      .city
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [_vm._v("Bureau")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.report
                                                              .organization
                                                              ? _vm.report
                                                                  .organization
                                                                  .name
                                                              : ""
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [_vm._v("Rapport type")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.report.type
                                                              ? _vm.report.type
                                                                  .name
                                                              : ""
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [_vm._v("Opnamedatum")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormatTime"
                                                            )(
                                                              _vm.report
                                                                .planned_at
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "profile.reportTag"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    _vm._l(
                                                      _vm.report.tags,
                                                      function(tag, index) {
                                                        return _c(
                                                          "p",
                                                          { key: index },
                                                          [
                                                            _vm.report.tags
                                                              .length > 1
                                                              ? _c("span", [
                                                                  tag[0]
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                tag.name
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "," +
                                                                              _vm._s(
                                                                                tag.name
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        tag.name
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [_vm._v("Naam TC")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _vm.report.organization_tc
                                                        ? _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.report
                                                                  .organization_tc
                                                                  .name
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _vm.report.isAannemersReport
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn",
                                                          attrs: { sm4: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn__fadedText"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Naam Aannemer"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.report.isAannemersReport
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn",
                                                          attrs: { sm4: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn__fadedText"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Naam Expert"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _vm.report
                                                        .organization_expert
                                                        ? _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.report
                                                                  .organization_expert
                                                                  .name
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.report.isAannemersReport
                                                ? _c(
                                                    "v-layout",
                                                    { attrs: { row: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn",
                                                          attrs: { sm4: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn__fadedText"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Naam Expert"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm.report.second_expert
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "infoPanelColumn",
                                                              attrs: { sm8: "" }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .second_expert
                                                                      .name
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Indieningsdatum"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              _vm.report
                                                                .applicant_submitted_at
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [_vm._v("Type gebouw")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _vm.generalKeys
                                                        .SoortGebouw
                                                        ? _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.generalKeys
                                                                  .SoortGebouw
                                                                  .value
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [_vm._v("Bouwjaar")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _vm.generalKeys.BouwJaar
                                                        ? _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.generalKeys
                                                                  .BouwJaar
                                                                  .value
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Eigendom sinds"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _vm.generalKeys
                                                        .EigendomSinds
                                                        ? _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.generalKeys
                                                                  .EigendomSinds
                                                                  .value
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Zaakbegeleider"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _vm.generalKeys
                                                        .presetAtTaxations_Name
                                                        ? _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.generalKeys
                                                                  .presetAtTaxations_Name
                                                                  .value
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Verhaal aanvrager"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _vm.generalKeys
                                                        .storyOfApplicant
                                                        ? _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.generalKeys
                                                                  .storyOfApplicant
                                                                  .value
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Opmerking deskundige"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _c("p", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.sanitizeString(
                                                              _vm.generalKeys
                                                                .ExpertComment
                                                                ? _vm
                                                                    .generalKeys
                                                                    .ExpertComment
                                                                    .value
                                                                : "-"
                                                            )
                                                          )
                                                        }
                                                      }),
                                                      _c("p", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.sanitizeString(
                                                              _vm.generalKeys
                                                                .OpmerkingDeskundige
                                                                ? _vm
                                                                    .generalKeys
                                                                    .OpmerkingDeskundige
                                                                    .value
                                                                : "-"
                                                            )
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Opmerking aanvrager"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _c("p", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.sanitizeString(
                                                              _vm.generalKeys
                                                                .relevantRemarksApplicant
                                                                ? _vm
                                                                    .generalKeys
                                                                    .relevantRemarksApplicant
                                                                    .value
                                                                : "-"
                                                            )
                                                          )
                                                        }
                                                      }),
                                                      _c("p", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.sanitizeString(
                                                              _vm.generalKeys
                                                                .OpmerkingAanvrager
                                                                ? _vm
                                                                    .generalKeys
                                                                    .OpmerkingAanvrager
                                                                    .value
                                                                : "-"
                                                            )
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Datum ondertekening TC"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm8: "" }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateFormatTime"
                                                              )(
                                                                _vm.report
                                                                  .approved_at
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Totaal schadebedrag incl. BTW"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm.report.total_repairs
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn",
                                                          attrs: { sm8: "" }
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  _vm.report
                                                                    .total_repairs
                                                                    .total_in
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "infoPanelColumn",
                                                      attrs: { sm4: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn__fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Calculatiemodel"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm.report.calculation_model
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "infoPanelColumn",
                                                          attrs: { sm8: "" }
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.report
                                                                    .calculation_model
                                                                    .name
                                                                ) +
                                                                " (" +
                                                                _vm._s(
                                                                  _vm.report
                                                                    .calculation_model
                                                                    .from
                                                                ) +
                                                                ") " +
                                                                _vm._s(
                                                                  _vm.vatText
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "approvalPanelContainer" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-xs-right handlepanel"
                                      },
                                      [
                                        _vm.hasPanelsOpen
                                          ? _c("v-flex", [
                                              _c(
                                                "div",
                                                { staticClass: "panel" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: _vm.closeAll
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" sluit alle "),
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "keyboard_arrow_up"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        !_vm.hasPanelsOpen
                                          ? _c("v-flex", [
                                              _c(
                                                "div",
                                                { staticClass: "panel" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      on: { click: _vm.openAll }
                                                    },
                                                    [
                                                      _vm._v(" open alle "),
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "keyboard_arrow_down"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.anomalies, function(
                                      anomaly,
                                      index
                                    ) {
                                      return [
                                        _c(
                                          "div",
                                          { key: index },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: {
                                                  wrap: "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: { xs: "", "12": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-expansion-panel",
                                                      {
                                                        staticClass:
                                                          "approvalPanel",
                                                        class: _vm.getStatusClass(
                                                          anomaly.damage.status
                                                        ),
                                                        attrs: { expand: "" },
                                                        model: {
                                                          value:
                                                            _vm.panels[index],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.panels,
                                                              index,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "panels[index]"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-expansion-panel-content",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  slot: "header"
                                                                },
                                                                slot: "header"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    attrs: {
                                                                      wrap: "",
                                                                      "align-center":
                                                                        "",
                                                                      "justify-space-between":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs7:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                anomaly.name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        staticClass:
                                                                          "text-xs-right",
                                                                        attrs: {
                                                                          xs5:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              bottom:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "div",
                                                                                        _vm._g(
                                                                                          {
                                                                                            class:
                                                                                              "ml-2 headerButton headerButton--reject " +
                                                                                              anomaly
                                                                                                .damage
                                                                                                .status,
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                $event.preventDefault()
                                                                                                return _vm.updateAnomaly(
                                                                                                  anomaly,
                                                                                                  {
                                                                                                    status:
                                                                                                      "rejected"
                                                                                                  }
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "custom"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "cancel"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Afwijking afkeuren"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              bottom:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "div",
                                                                                        _vm._g(
                                                                                          {
                                                                                            class:
                                                                                              "ml-2 headerButton headerButton--accept " +
                                                                                              anomaly
                                                                                                .damage
                                                                                                .status,
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                $event.preventDefault()
                                                                                                return _vm.updateAnomaly(
                                                                                                  anomaly,
                                                                                                  {
                                                                                                    status:
                                                                                                      "approved"
                                                                                                  }
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "custom"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "check_circle"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Afwijking goedkeuren"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "panel"
                                                              },
                                                              [
                                                                _vm.panels
                                                                  ? _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "v-layout",
                                                                          {
                                                                            attrs: {
                                                                              wrap:
                                                                                "",
                                                                              row:
                                                                                "",
                                                                              "mt-3":
                                                                                "",
                                                                              "px-4":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  xs12:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-layout",
                                                                                  {
                                                                                    staticClass:
                                                                                      "item__list",
                                                                                    attrs: {
                                                                                      wrap:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        staticClass:
                                                                                          "label",
                                                                                        attrs: {
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-layout",
                                                                                          {
                                                                                            attrs: {
                                                                                              wrap:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                attrs: {
                                                                                                  xs12:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "Informatie"
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        staticClass:
                                                                                          "list__item",
                                                                                        attrs: {
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-layout",
                                                                                          {
                                                                                            attrs: {
                                                                                              wrap:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-flex",
                                                                                              {
                                                                                                attrs: {
                                                                                                  xs12:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "AnomalyCustomProperties",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "tw-text-3xl tw-mb-16",
                                                                                                    attrs: {
                                                                                                      anomaly: anomaly
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                                anomaly.damage &&
                                                                                                anomaly
                                                                                                  .damage
                                                                                                  .casuality_has_deviated
                                                                                                  ? [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tw-text-base tw-font-bold"
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "Reden"
                                                                                                          )
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tw-text-lg tw-mb-16"
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm
                                                                                                                  .overwriteConclusionReasonLabels[
                                                                                                                  anomaly
                                                                                                                    .damage
                                                                                                                    .casuality_anomaly_reason
                                                                                                                ]
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tw-text-base tw-font-bold"
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "Toelichting"
                                                                                                          )
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tw-text-lg"
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                anomaly
                                                                                                                  .damage
                                                                                                                  .casuality_anomaly_explanation
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ]
                                                                                                  : _vm._e()
                                                                                              ],
                                                                                              2
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  xs12:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-select",
                                                                                  {
                                                                                    attrs: {
                                                                                      items:
                                                                                        _vm.rejectAnomalyReasons,
                                                                                      "item-value":
                                                                                        "id",
                                                                                      "item-text":
                                                                                        "name",
                                                                                      label:
                                                                                        "Afwijsreden",
                                                                                      multiple:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      change: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.updateAnomalyRejectReasonDebounce(
                                                                                          anomaly,
                                                                                          $event,
                                                                                          "reject_reasons"
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        anomaly
                                                                                          .damage
                                                                                          .reject_reasons,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          anomaly.damage,
                                                                                          "reject_reasons",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "anomaly.damage.reject_reasons"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  xs12:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-textarea",
                                                                                  {
                                                                                    attrs: {
                                                                                      name:
                                                                                        "comment",
                                                                                      label:
                                                                                        "Afwijsopmerkingen",
                                                                                      value:
                                                                                        anomaly
                                                                                          .damage
                                                                                          .reject_comment
                                                                                    },
                                                                                    on: {
                                                                                      change: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.updateAnomalyRejectReason(
                                                                                          anomaly,
                                                                                          $event,
                                                                                          "reject_comment"
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]),
                            _c("v-flex", { attrs: { xs12: "", lg7: "" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--iframe profile"
                                },
                                [
                                  !_vm.fileUrl && _vm.report.is_final
                                    ? _c("p", [
                                        _vm._v(
                                          " Er is een probleem opgetreden met de PDF van dit rapport. Graag contact opnemen met de helpdesk. "
                                        )
                                      ])
                                    : _vm._e(),
                                  !_vm.report.is_final
                                    ? _c("p", [
                                        _vm._v(
                                          "Dit rapport is nog niet afgerond."
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.fileUrl && _vm.report.is_final
                                    ? _c("iframe", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "96%",
                                          "min-height": "600px"
                                        },
                                        attrs: { src: _vm.fileUrl }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _c("ApprovalBar", {
              attrs: { backUrl: "/reports/" + _vm.report.uuid },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: _vm.isLockedByUser,
                                  color: "warning",
                                  small: "true",
                                  icon: "cancel"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleApproveDialog(
                                      "structural_engineer_rejected",
                                      "Versturen naar:",
                                      "rejected"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Afkeuren ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: _vm.isLockedByUser,
                                  color: "success",
                                  small: "true",
                                  icon: "check_circle"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleApproveDialog(
                                      "approved",
                                      "Versturen naar:",
                                      "approved"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Goedkeuren ")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                427556862
              )
            })
          ]
        : _vm._e(),
      _vm.isDisplayingApprovalDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isDisplayingApprovalDialog,
                callback: function($$v) {
                  _vm.isDisplayingApprovalDialog = $$v
                },
                expression: "isDisplayingApprovalDialog"
              }
            },
            [
              _c("ApprovalDialog", {
                attrs: {
                  report: _vm.report,
                  title: _vm.title,
                  status: _vm.status,
                  role: "veldwerk",
                  approval: _vm.approval,
                  firstTimeRight: _vm.approval ? true : false
                },
                model: {
                  value: _vm.isDisplayingApprovalDialog,
                  callback: function($$v) {
                    _vm.isDisplayingApprovalDialog = $$v
                  },
                  expression: "isDisplayingApprovalDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }