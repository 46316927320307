/* eslint-disable guard-for-in */
import { Component } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import ErrorHandler from '@/support/ErrorHandler';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';
import { Panel } from '@/components/damage-panel/DamagePanel';
import { Report, Answer } from '@/models/Report';
import { RejectReason as RejectReasonModel } from '@/models/RejectReason';
import { AlertBar } from '@/support/AlertBar';
import { Lock } from '@/support/Lock';
import IdleWarningDialog from '@/components/IdleWarningDialog';
import { sanitizeString } from '@/support/String';
import { Anomaly, AnomalyIncludes, AnomalyType } from '@/models/Anomaly';
import AnomalyCustomProperties from '@/components/AnomalyCustomProperties/AnomalyCustomProperties.vue';
import { DamageStatus, DamageStatusEnum } from '@/models/Damage';
import { overwriteConclusionReasonLabels } from '@/items/OverwriteConclusionDialogReasons';

@Component<StructuralEngineer>({
  components: {
    AnomalyCustomProperties,
  },
})
export default class StructuralEngineer extends IdleWarningDialog {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  public $pageTitle = 'Bouwkundige controle';

  protected lock: Lock = new Lock();

  protected lockKey = '';

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  protected isLoading = false;

  protected report: Report | null = null;

  protected anomalies: Anomaly[] | null = null;

  protected anomalyRejectReasons = [];

  protected isDisplayingApprovalDialog = false;

  protected status = '';

  protected title = '';

  protected approval = '';

  protected updateAnomalyRejectReasonDebounce: Function = debounce(this.updateAnomalyRejectReason, 500);

  protected rejectAnomalyReasons: RejectReasonModel[] = [];

  protected generalKeys: GeneralKey = GeneralKeys;

  protected panels: Panel[] | null = [];

  protected hasPanelsOpen = false;

  // #endregion

  // #region Lifecycle Hooks / Init
  protected created(): void {
    window.onbeforeunload = () => {
      this.lock.unlock(this.lockKey);
    };
  }

  public destroyed() {
    this.lock.unlock(this.lockKey);
  }

  protected mounted(): void {
    this.createLock();
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;
    await this.getReport();
    await this.getAnomalies();
    await this.getAnomalyRejectReasons();
    this.initBreadcrumb();
    this.setGeneralInfo();
    this.isAllowedToViewDialog = true;
    this.isLoading = false;
  }
  // #endregion

  // #region Class methods
  protected async createLock() {
    this.lockKey = `report:${this.$route.params.id}:editor:bouwkundige-controle`;
    await this.lock.checkLock(this.lockKey);
    await this.lock.initLock(this.lockKey);
    this.setAlert();
  }

  protected setAlert() {
    if (this.lock.user) {
      this.$store.state.alert.message = AlertBar.setIsLockedMessage(this.lock.user);
      this.$store.state.alert.show = true;
    } else {
      this.$store.state.alert.show = false;
    }
  }

  protected toggleApproveDialog(status: string, title: string, approval: string) {
    this.status = status;
    this.title = title;
    this.approval = approval;
    this.isDisplayingApprovalDialog = true;
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.report?.case_number, path: `/reports/${this.report?.uuid}` },
          { name: 'Bouwkundige controle' },
        ],
      });
  }

  protected setGeneralInfo() {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.generalKeys) {
      this.generalKeys[key].value = '';
    }

    const keys = Object.keys(this.generalKeys);
    if (! this.report?.answers) {
      return;
    }

    this.report?.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected getStatusClass(status: DamageStatus) {
    switch (status) {
      case DamageStatusEnum.REJECTED:
        return 'approvalPanel--rejected';
      case DamageStatusEnum.ACCEPTED:
        return 'approvalPanel--accepted';
      case DamageStatusEnum.CHANGED:
        return 'approvalPanel--changed';
      default:
        return '';
    }
  }

  protected openAll() {
    if (this.anomalies) {
      this.panels = [...Array(this.anomalies.length).keys()].map((_) => [true]);
    }
    this.hasPanelsOpen = true;
  }

  protected closeAll() {
    this.panels = [];
    this.hasPanelsOpen = false;
  }

  protected navigateToDamage(): void {
    const routeData = this.$router.resolve(`/reports/${this.report?.uuid}/schades`);
    window.open(routeData.href, '_blank');
  }
  // #endregion

  // #region Async methods
  protected async getReport(): Promise<void> {
    try {
      this.report = await new Report()
        .include(['applicant', 'answers', 'total_repairs', 'organization'])
        .find(this.$route.params.id);
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected async getAnomalyRejectReasons(): Promise<void> {
    this.rejectAnomalyReasons = await new RejectReasonModel()
      .filter({ type: 'anomaly' })
      .limit(100)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getAnomalies(): Promise<void> {
    try {
      const anomalies: Anomaly[] = await new Anomaly()
        .include([AnomalyIncludes.DAMAGE, AnomalyIncludes.REJECT_REASONS])
        .filter({ report: this.report?.uuid, type: AnomalyType.ASSESSMENT_SCHEME })
        .all();

      this.anomalies = anomalies.filter((anomaly) => anomaly.type !== AnomalyType.DAMAGE_AMOUNT);

      this.panels = [...Array(this.anomalies?.length).keys()].map((_) => [true]);
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected async updateAnomalyRejectReason(anomaly: Anomaly, value: string | string[], key: string) {
    const payload = {
      damage: {
        [key]: value,
      },
    };

    try {
      await new Anomaly(anomaly)
        .include([AnomalyIncludes.DAMAGE, AnomalyIncludes.REJECT_REASONS])
        .update(payload);
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected async updateAnomaly(anomaly: Anomaly, payload: {[key: string]: any}) {
    try {
      const newAnomaly = await new Anomaly(anomaly)
        .include([AnomalyIncludes.DAMAGE, AnomalyIncludes.REJECT_REASONS])
        .update(payload);

      const index = this.anomalies?.findIndex((anomalyItem) => anomalyItem.id === newAnomaly.id);

      if (this.anomalies && (index !== undefined) && index >= 0) {
        this.$set(this.anomalies, index, newAnomaly);
      }
    } catch (error) {
      ErrorHandler.network(error);
    }
  }
  // #endregion

  // #region Getters & Setters
  protected get isLockedByUser(): boolean {
    return this.lock.isLockedByUser;
  }

  protected get fileUrl(): string | null {
    return this.report?.links.quick;
  }

  protected get vatText() {
    if (this.report && this.report.calculation_model_vat_low) {
      return `met ${this.report.calculation_model_vat_low}% BTW`;
    }
    return '';
  }

  protected get overwriteConclusionReasonLabels() {
    return overwriteConclusionReasonLabels;
  }

  // #endregion

  // #region @Watchers
  // #endregion
}
